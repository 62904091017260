import { LangCode } from "./lang";

export enum NewsType {
  GENERAL = "GENERAL",
  BREAKING = "BREAKING",
  ATTENTION = "ATTENTION",
  TECH = "TECH",
}

export interface News {
  id: number;
  title: Record<LangCode, string>;
  content: Record<LangCode, string>;
  type: NewsType;
  onlyForUserIds: number[];
  isActive: boolean;
  createdAt: string;
  publishAfter: string;
}
