import { Component, Vue, Ref, Prop } from "vue-property-decorator";
import { formatDate } from "@helpers";
import { NewsType } from "@/admin/types/news";
import { MessageType } from "@/types/message";

@Component
export default class NewsNotification extends Vue {
  @Ref("content")
  private readonly contentRef!: HTMLDivElement;
  @Ref("title") private readonly titleRef!: HTMLDivElement;

  @Prop({ type: Boolean, default: false })
  private readonly collapsed!: boolean;
  @Prop({ type: String, default: "" })
  private readonly title!: string;
  @Prop({ type: String, default: "" })
  private readonly content!: string;
  @Prop({ type: String, default: "" })
  private readonly date!: string;
  @Prop({ type: String, default: NewsType.GENERAL })
  private readonly type!: string;
  @Prop({ type: Boolean, default: false })
  private readonly hiddenExpandButton!: boolean;

  private hasMoreText = false;
  private expandedNews = false;

  private get localListeners() {
    const { click } = this.$listeners;
    const listeners: Record<string, unknown> = {};

    if (click) {
      listeners.click = click;
    }

    return listeners;
  }

  private get iconName() {
    if (this.type === NewsType.ATTENTION) {
      return "$alert";
    }

    if (this.type === NewsType.TECH) {
      return "$toolsWrench";
    }

    if (this.type === NewsType.BREAKING) {
      return "$newspaper";
    }

    if (this.type === MessageType.FACEBOOK_CODE_EVENT) {
      return "$facebook";
    }

    return "$bell";
  }

  private get notificationType() {
    if (this.type === MessageType.FACEBOOK_CODE_EVENT) {
      return "breaking";
    }

    return this.type.toLowerCase();
  }

  private get formatedDate() {
    return formatDate(this.date, { showTime: true });
  }

  private get canClose() {
    return !!this.$listeners.close;
  }

  private onClick() {
    this.$emit("click");
  }

  private onClose() {
    this.$emit("close");
  }

  private onClickShowMore() {
    if (this.$listeners.click) {
      this.$emit("click:show-more");
    } else {
      this.toggleExpandNews();
    }
  }

  private toggleExpandNews(expanded = !this.expandedNews) {
    this.expandedNews = expanded;
  }

  private mounted() {
    const checkHasMore = () => {
      this.$nextTick(() => {
        if (this.expandedNews) return;

        this.hasMoreText =
          this.contentRef.offsetHeight < this.contentRef.scrollHeight ||
          this.titleRef.offsetHeight < this.titleRef.scrollHeight;

        this.expandedNews = !this.hasMoreText;
      });
    };

    const visibleInViewport = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          checkHasMore();
          this.$emit("visible-in-viewport");
        }
      },
      {
        threshold: [0.5],
      }
    );

    visibleInViewport.observe(this.$el);

    this.$watch(
      () => {
        return [this.content, this.title].join("-");
      },
      () => {
        this.expandedNews = false;
        checkHasMore();
      }
    );

    this.$watch(
      () => {
        return this.collapsed;
      },
      (collapsed) => {
        if (collapsed) {
          this.toggleExpandNews(false);
        }
      },
      {
        immediate: true,
      }
    );

    this.$once("hook:beforeDestroy", () => {
      visibleInViewport.disconnect();
    });
  }
}
